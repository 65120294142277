<!-- eslint-disable vue/no-v-html -->
<template>
	<v-layout v-if="companies && selectedCompany && !loading" fill-height column>
		<v-flex v-show="!mini" shrink px-3>
			<v-text-field v-model="companiesSearch" hide-details single-line clearable :label="$t('actions.search')" prepend-inner-icon="search"></v-text-field>
		</v-flex>
		<v-flex v-show="!mini" shrink>
			<v-layout row justify-space-around>
				<v-flex shrink>
					<v-tooltip bottom>
						<template v-slot:activator="{ on }">
							<v-btn icon v-on="on" @click="showFilters = !showFilters">
								<v-icon :color="filtersSet ? 'error' : 'primary'">filter_list</v-icon>
							</v-btn>
						</template>
						<span v-t="'actions.filter'" />
					</v-tooltip>
				</v-flex>
				<v-flex shrink>
					<v-tooltip bottom>
						<template v-slot:activator="{ on }">
							<v-btn icon v-on="on" @click="doSortByName()">
								<v-badge color overlap :top="sortByName === true" :bottom="sortByName === false">
									<template v-slot:badge>
										<v-icon color="secondary">{{ sortByName === null ? '' : sortByName ? 'arrow_upward' : 'arrow_downward' }}</v-icon>
									</template>
									<v-icon color="primary">sort_by_alpha</v-icon>
								</v-badge>
							</v-btn>
						</template>
						<span>
							{{ sortByName === null ? $t('documents.sort_by_name_asc') : sortByName ? $t('documents.sort_by_name_desc') : $t('documents.do_not_sort') }}
						</span>
					</v-tooltip>
				</v-flex>
				<v-flex shrink>
					<v-tooltip bottom>
						<template v-slot:activator="{ on }">
							<v-btn icon v-on="on" @click="doSortByDate()">
								<v-badge color overlap :top="sortByDate === true" :bottom="sortByDate === false">
									<template v-slot:badge>
										<v-icon color="secondary">{{ sortByDate === null ? '' : sortByDate ? 'arrow_upward' : 'arrow_downward' }}</v-icon>
									</template>
									<v-icon color="primary">update</v-icon>
								</v-badge>
							</v-btn>
						</template>
						<span>
							{{ sortByDate === null ? $t('documents.sort_by_date_asc') : sortByDate ? $t('documents.sort_by_date_desc') : $t('documents.do_not_sort') }}
						</span>
					</v-tooltip>
				</v-flex>
			</v-layout>
		</v-flex>
		<v-divider v-if="!mini && showFilters" />
		<v-flex v-if="!mini && showFilters" shrink>
			<v-layout column pa-3>
				<v-flex v-t="'documents.filters.intro'" align-center />
				<v-checkbox
					v-model="onlyFavorites"
					:disabled="(!favoritesCompanies || favoritesCompanies.length === 0) && !onlyFavorites"
					hide-details
					color="primary"
					:label="$t('documents.filters.favorites')"
				/>
				<DatePicker v-model="closingDateFilter" :label="$t('customer.general.closing_date')" />
			</v-layout>
		</v-flex>
		<v-divider v-if="!mini && !onlyFavorites" />
		<!-- SAFARI BUG FIX : DO NOT REMOVE height: 0 ! -->
		<v-flex grow scroll-y style="height: 0">
			<v-layout fill-height column>
				<v-flex shrink>
					<v-subheader v-t="'company.companies'"></v-subheader>
				</v-flex>
				<!-- SAFARI BUG FIX : DO NOT REMOVE height: 0 ! -->
				<v-flex scroll-y grow style="height: 0">
					<w-virtual-list ref="virtualList" :dense="mini" :items="allCompanies" :item-index-to-scroll-to="indexToScrollTo">
						<template v-slot:default="{ item: company }">
							<v-hover>
								<template v-slot:default="{ hover }">
									<v-list-tile avatar :value="company.id === selectedCompany.id" @click="selectCompany(company)">
										<v-list-tile-avatar v-if="mini">
											<v-tooltip bottom>
												<template v-slot:activator="{ on }">
													<v-avatar size="2.5em" class="pointer" color="primary" v-on="on">
														<v-flex white--text>{{ company.abbreviation }}</v-flex>
													</v-avatar>
												</template>
												<span v-if="company.client_code" v-text="`${company.company} (${company.client_code})`" />
												<span v-else v-text="company.company" />
											</v-tooltip>
										</v-list-tile-avatar>
										<v-list-tile-avatar v-else>
											<v-avatar size="2.5em" class="pointer" color="primary">
												<v-badge color="" left>
													<template v-slot:badge>
														<v-icon v-show="company.favorite" medium color="yellow">star</v-icon>
													</template>
													<v-flex white--text>{{ company.abbreviation }}</v-flex>
												</v-badge>
											</v-avatar>
										</v-list-tile-avatar>
										<v-list-tile-content v-show="!mini">
											<v-list-tile-title>
												<v-flex v-if="!hover" text-truncate v-html="company.companyHtml" />
												<v-tooltip v-else bottom>
													<template v-slot:activator="{ on }">
														<v-flex v-on="on" v-html="company.companyHtml" />
													</template>
													<v-flex text-truncate v-text="company.client_code ? `${company.company} (${company.client_code})` : company.company" />
												</v-tooltip>
											</v-list-tile-title>
										</v-list-tile-content>
										<v-list-tile-action v-show="!mini && hover">
											<v-fade-transition v-if="!mini">
												<v-flex v-if="hover" shrink align-center>
													<v-tooltip top>
														<template v-slot:activator="{ on }">
															<v-btn icon v-on="on" @click.stop="toggleFavorite(company)">
																<v-icon color="yellow">{{ company.favorite ? 'star' : 'star_border' }}</v-icon>
															</v-btn>
														</template>
														<span>{{ company.favorite ? $t('documents.favorites.remove') : $t('documents.favorites.add') }}</span>
													</v-tooltip>
												</v-flex>
											</v-fade-transition>
										</v-list-tile-action>
									</v-list-tile>
								</template>
							</v-hover>
						</template>
					</w-virtual-list>
				</v-flex>
			</v-layout>
		</v-flex>
	</v-layout>
</template>

<script>
import { mapState } from 'vuex'
import CustomersManagerModuleGuard from '@/mixins/ModulesGuards/CustomersManager/CustomersManagerModuleGuard'

export default {
	name: 'CompaniesList',
	components: {
		DatePicker: () => ({
			component: import('@/components/Commons/Pickers/DatePicker')
		})
	},
	mixins: [CustomersManagerModuleGuard],
	data: function () {
		return {
			loading: false,
			companiesSearch: '',
			showFilters: false,
			onlyFavorites: false,
			closingDateFilter: null,
			orderedAlphaAsc: null,
			sidebar: false,
			mini: false,
			sortByDate: null,
			sortByName: null
		}
	},
	computed: {
		...mapState({
			companies: state => state.company.list,
			selectedCompany: state => state.company.selected
		}),
		filtersSet: function () {
			let result = false
			if (this.onlyFavorites || this.onlyWithNotification) {
				result = true
			}
			return result
		},
		showFavorites: function () {
			let result = true
			if (this.mini || (this.companiesSearch && this.companiesSearch.trim().length > 0) || (this.favoritesCompanies && this.favoritesCompanies.length === 0)) {
				result = false
			}
			return result
		},
		sortedCompanies: function () {
			return this.doSort(this.companies)
		},
		favoritesCompanies: function () {
			let result = this.sortedCompanies.filter(company => company.favorite)
			if (this.onlyWithNotification) {
				result = result.filter(company => company.notifications && company.notifications > 0)
			}
			return result
		},
		allCompanies: function () {
			return this.doSort(this.filteredCompanies)
		},
		filteredCompanies: function () {
			let result = this.sortedCompanies
			if (this.onlyFavorites) {
				result = result.filter(company => company.favorite)
			}
			const companiesWithHtmlName = this.addCompaniesHtmlName(result)
			const companiesWithNotifications = this.filterCompaniesOnlyWithNotifications(companiesWithHtmlName)
			const companiesWithClosingDate = this.filterCompaniesWithClosingDate(companiesWithNotifications)
			return this.filterCompaniesWithSearch(companiesWithClosingDate)
		},
		indexToScrollTo: function () {
			return this.allCompanies.findIndex(company => company.id === this.selectedCompany.id)
		}
	},
	watch: {
		mini: {
			deep: true,
			handler: function () {
				this.appEventBus.emit(this.appEvents.VIRTUAL_SCROLL_RESIZE)
			}
		},
		favoritesCompanies: {
			deep: true,
			handler: function () {
				this.appEventBus.emit(this.appEvents.VIRTUAL_SCROLL_RESIZE)
			}
		},
		showFilters: {
			deep: true,
			handler: function () {
				this.appEventBus.emit(this.appEvents.VIRTUAL_SCROLL_RESIZE)
			}
		},
		sidebar: {
			deep: true,
			handler: function () {
				this.appEventBus.emit(this.appEvents.VIRTUAL_SCROLL_RESIZE)
			}
		},
		companies: {
			handler: function () {
				this.appEventBus.emit(this.appEvents.VIRTUAL_SCROLL_RESIZE)
			}
		}
	},
	methods: {
		addCompaniesHtmlName: function (list) {
			return [...list].map(item => {
				item.companyHtml = item.company
				if (item.client_code) {
					item.companyHtml += ' (' + item.client_code + ')'
				}
				return item
			})
		},
		filterCompaniesWithSearch: function (list) {
			let result = list
			if (this.companiesSearch && this.companiesSearch.trim().length > 0) {
				let results = []
				list
					.filter(
						company =>
							company.company.toLowerCase().startsWith(this.companiesSearch.toLowerCase()) ||
							(company.client_code && company.client_code.toLowerCase().startsWith(this.companiesSearch.toLowerCase))
					)
					.forEach(company => {
						let companyCopy = { ...company, prop: companyCopy }
						results.push(companyCopy)
					})
				list
					.filter(company => results.filter(retainedCompany => retainedCompany.id == company.id).length === 0)
					.forEach(company => {
						if (
							company.company.toLowerCase().includes(this.companiesSearch.toLowerCase()) ||
							(company.client_code && company.client_code.toLowerCase().includes(this.companiesSearch.toLowerCase()))
						) {
							let companyCopy = { ...company, prop: companyCopy }
							results.push(companyCopy)
						}
					})
				result = results.map(company => {
					let companyName = company.company
					if (company.client_code) {
						companyName += ' (' + company.client_code + ')'
					}
					let start = companyName.toLowerCase().indexOf(this.companiesSearch.toLowerCase())
					let end = start + this.companiesSearch.length

					let begining = companyName.substring(0, start)
					let middle = companyName.substring(start, end)
					let ending = companyName.substring(end, companyName.length)
					company.companyHtml = begining + '<b>' + middle + '</b>' + ending
					return company
				})
			}
			return result
		},
		filterCompaniesWithClosingDate: function (list) {
			let result = list
			if (this.closingDateFilter) {
				const closingDateFilterParts = this.closingDateFilter.split('-')
				const month = closingDateFilterParts[1]
				const day = closingDateFilterParts[2]
				result = list.filter(vendor => {
					if (!vendor.details.closing_date) {
						return false
					}
					const vendorDetailsClosingDate = vendor.details.closing_date.split('-')
					const m = vendorDetailsClosingDate[1]
					const d = vendorDetailsClosingDate[2]
					return m === month && d.substring(0, 2) === day
				})
			}
			return result
		},
		filterCompaniesOnlyWithNotifications: function (list) {
			let result = list
			if (this.onlyWithNotification) {
				result = list.filter(company => company.notifications && company.notifications > 0)
			}
			return result
		},
		getAppEventsActionsMapping: function () {
			return [
				{ event: this.appEvents.SIDEBAR_MINIMIZED, action: this.onSidebarMinimized },
				{ event: this.appEvents.SIDEBAR_MAXIMIZED, action: this.onSidebarMaximized },
				{ event: this.appEvents.SHOW_SIDEBAR, action: this.onShowSideBar },
				{ event: this.appEvents.HIDE_SIDEBAR, action: this.onHideSideBar }
			]
		},
		onSidebarMaximized: function () {
			this.mini = false
		},
		onSidebarMinimized: function () {
			this.mini = true
		},
		onShowSideBar: function () {
			this.sidebar = true
		},
		onHideSideBar: function () {
			this.sidebar = false
		},
		selectCompany: function (company) {
			const currentRouteName = this.$route.name
			let routeName
			const matchingSubString = currentRouteName.match(/^customer-[a-z]*/)
			if (matchingSubString) {
				routeName = matchingSubString[0]
			}

			this.appService.goTo({
				name: routeName,
				params: {
					vendor_id: company.id
				}
			})
		},
		doSortByName: function () {
			if (this.sortByName == null) {
				this.sortByName = true
			} else if (this.sortByName) {
				this.sortByName = false
			} else {
				this.sortByName = null
			}
			this.sortByDate = null
		},
		doSortByDate: function () {
			if (this.sortByDate == null) {
				this.sortByDate = true
			} else if (this.sortByDate) {
				this.sortByDate = false
			} else {
				this.sortByDate = null
			}
			this.sortByName = null
		},
		doSort: function (values) {
			let result = [...values]
			if (this.sortByName) {
				result.sort(this.service.compareValues('company', 'asc'))
			} else if (this.sortByName === null) {
				result.sort(this.service.compareValues('company', 'asc'))
				result.sort(this.service.compareValues('favorite', 'desc'))
			} else if (this.sortByName === false) {
				result.sort(this.service.compareValues('company', 'desc'))
			}
			if (this.sortByDate !== null) {
				if (this.sortByDate) {
					result.sort(this.service.compareValues('created_at', 'asc'))
				} else {
					result.sort(this.service.compareValues('created_at', 'desc'))
				}
			}
			return result
		},
		toggleFavorite: function (company = null) {
			if (company) {
				if (!company.favorite) {
					this.service.addToFavorites(company).then(() => {
						company.favorite = true
					})
				} else {
					this.service.removeFromFavorites(company).then(() => {
						company.favorite = false
					})
				}
			}
		}
	}
}
</script>
