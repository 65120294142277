<template>
	<v-layout column fill-height>
		<v-flex shrink>
			<v-tabs centered icons-and-text next-icon="mdi-arrow-right-bold-box-outline" prev-icon="mdi-arrow-left-bold-box-outline" show-arrows :value="activeTab">
				<v-tabs-slider color="primary"></v-tabs-slider>
				<v-tab v-for="section in menuCompanyDetails" :key="section.key" :disabled="section.disabled" @click="goToSection(section)">
					{{ section.title }}
					<v-icon>{{ section.icon }}</v-icon>
				</v-tab>
			</v-tabs>
		</v-flex>
		<v-flex v-if="companySelected && companySelected.id" fill-height scroll-y>
			<keep-alive>
				<router-view :key="routeName" :company="companySelected" />
			</keep-alive>
		</v-flex>
	</v-layout>
</template>

<script>
import { mapState } from 'vuex'
import CustomersManagerModuleGuard from '@/mixins/ModulesGuards/VendorSettings/VendorsSettingsModuleGuard'
import CompaniesList from '@/components/CustomersManager/CompaniesList'
import Vue from 'vue'
import HandleSideBarMixin from "@/mixins/SideBar/HandleSideBarMixin";

export default {
	name: 'CustomerDetails',
	mixins: [
		CustomersManagerModuleGuard,
		HandleSideBarMixin,
	],
	data: function () {
		return {
			hasAdministrationModule: false,
		}
	},
	computed: {
		...mapState({
			showSideBar: state => state.sideBar.show,
			companies: state => state.company.list,
			companySelected: state => state.company.selected,
			isAccountant: state => state.auth.isAccountant,
			userRoles: state => state.company.userRoles,
			accountingFirm: state => state.accountingFirm.selected
		}),
		activeTab: function () {
			let routeName = this.$route.name.split('-').pop()
			return this.menuCompanyDetails.findIndex(tab => {
				return tab.route === routeName
			})
		},
		isArchivedCustomer: function () {
			return !!this.companySelected?.deleted_at
		},
		menuCompanyDetails: function () {
			let index = 1
			const result = []
			const isArchivedCustomer = this.isArchivedCustomer
			result.push({
				disabled: isArchivedCustomer,
				title: this.$t('customers.tabs_title.details'),
				icon: 'folder',
				key: index,
				route: 'general'
			})
			index++
			result.push({
				disabled: isArchivedCustomer,
				title: this.$t('customers.tabs_title.address'),
				icon: 'place',
				key: index,
				route: 'addresses'
			})
			index++
			if (this.isAccountant) {
				result.push({
					disabled: isArchivedCustomer,
					title: this.$t('customers.tabs_title.fiscal'),
					icon: 'attach_money',
					key: index,
					route: 'fiscal'
				})
				index++
			}
			result.push({
				disabled: isArchivedCustomer,
				title: this.$t('customers.tabs_title.bank'),
				icon: 'account_balance',
				key: index,
				route: 'banks'
			})
			index++
			if (this.hasAccessToModule('automation') || this.hasAccessToModule('bill-scrapper')) {
				result.push({
					disabled: isArchivedCustomer,
					title: this.$t('customers.tabs_title.automation'),
					icon: 'autorenew',
					key: index,
					route: 'automation'
				})
				index++
			}
			result.push({
				disabled: isArchivedCustomer,
				title: this.$t('customers.tabs_title.periods'),
				icon: 'calendar_today',
				key: index,
				route: 'periods'
			})
			index++
			if (this.hasAccessToModule('themes')) {
				result.push({
					disabled: isArchivedCustomer,
					title: this.$t('customers.tabs_title.themes'),
					icon: 'security',
					key: index,
					route: 'themes'
				})
				index++
			}
			result.push({ disabled: isArchivedCustomer, title: this.$t('customers.tabs_title.users'), icon: 'person', key: index, route: 'users' })
			index++
			if (this.hasAccessToModule('project-management')) {
				result.push({
					disabled: isArchivedCustomer,
					title: this.$t('customers.tabs_title.offers'),
					icon: 'local_offer',
					key: index,
					route: 'services'
				})
				index++
			}
			if (this.hasAccessToModule('workflow')) {
				result.push({
					disabled: isArchivedCustomer,
					title: this.$t('customers.tabs_title.workflow'),
					icon: 'fas fa-project-diagram',
					key: index,
					route: 'workflows'
				})
				index++
			}
			if (this.isAccountant) {
				result.push({
					disabled: isArchivedCustomer,
					title: this.$t('customers.tabs_title.notes'),
					icon: 'description',
					key: index,
					route: 'notes'
				})
				index++
				if (this.hasAdministrationModule) {
					result.push({
						title: this.$t('customers.tabs_title.administration'),
						icon: 'fas fa-shield-alt',
						key: index,
						route: 'administration'
					})
				}
			}
			return result
		},
		routeName: function () {
			let result
			if (this.$route.name.startsWith('customer-')) {
				result = this.$route.name.replace('customer-', '').split('-')[0]
			} else {
				result = this.$route.name.replace('company-settings-', '').split('-')[0]
			}
			return result
		}
	},
	watch: {
		'$route.name': {
			handler: function (val) {
				if (val === 'customer') {
					this.appService.goTo('customer-general', true)
				} else if (val === 'company-settings') {
					this.appService.goTo('company-settings-general', true)
				}
			},
			immediate: true
		},
		selectedAccountingFirm: function () {
			this.setMenuCompanyDetails()
			if (this.isAccountant) {
				this.appService.goTo('customers', true)
			}
		},
		companies: {
			handler: function (val) {
				if (val?.length > 0) {
					this.setMenuCompanyDetails()
					this.getCompaniesDetails()
				}
				if (val?.length > 1 && !this.showSideBar && this.isAccountant && !this.isArchivedCustomer) {
					const CompaniesListClass = Vue.extend(CompaniesList)
					const companiesListComponent = new CompaniesListClass({
						parent: this
					})
					this.appEventBus.emit(this.appEvents.SET_SIDEBAR_CONTENT, {
						canMinimize: this.$vuetify.breakpoint.mdAndUp,
						startHidden: this.$vuetify.breakpoint.smAndDown,
						canClose: this.$vuetify.breakpoint.smAndDown,
						title: this.$t('client_workspace.tasks.filters.companies'),
						moduleInstance: companiesListComponent,
						emitter: this
					})
					this.setHasSidebar(true)
					this.setShowSidebar(this.$vuetify.breakpoint.mdAndUp);
				} else if (val?.length < 1 && this.showSideBar) {
					this.appEventBus.emit(this.appEvents.CLEAR_SIDEBAR_CONTENT, this)
					this.setHasSidebar(false)
					this.setShowSidebar(false)
				}
			},
			immediate: true
		}
	},
	created: function () {
		this.setMenuCompanyDetails()
	},
	mounted: function () {
		// Ugly hack to show the arrow on the tab slider
		setTimeout(() => {
			window.dispatchEvent(new Event('resize'))
		}, 400)
	},
	destroyed: function () {
		this.appEventBus.emit(this.appEvents.CLEAR_SIDEBAR_CONTENT, this)
		this.setHasSidebar(false)
	},
	methods: {
		goToSection: function (section) {
			let route = section.route
			if (this.isAccountant) {
				route = `customer-${route}`
			} else {
				route = `company-settings-${route}`
			}
			this.appService.goTo(route, true)
		},
		hasAccessToModule: function (moduleName) {
			return this.appService.hasModule(moduleName)
		},
		setMenuCompanyDetails: function () {
			const accountingFirmId = this.accountingFirm.id
			if (accountingFirmId && !this.isArchivedCustomer) {
				if (this.isAccountant) {
					this.service.hasAccessToAdministrationModule(accountingFirmId).then(isAccessible => {
						this.hasAdministrationModule = isAccessible
					})
				} else {
					this.hasAdministrationModule = false
				}
			} else {
				this.hasAdministrationModule = false
			}
		},
		getCompaniesDetails: function () {
			if (!this.isAccountant || this.isArchivedCustomer) {
				return
			}
			this.appEventBus.emit(this.appEvents.UPDATE_LOADING_STATUS, true)
			const accountingFirmId = this.accountingFirm.id
			this.service
				.getCustomers(accountingFirmId)
				.then(() => {
					if (this.vendorId == null && this.companies.length > 0) {
						return this.$store.dispatch('company/setCompany', this.companies[0])
					}
				})
				.then(() => {
					if (this.vendorId != null) {
						this.appService.goTo(
							{
								params: {
									...this.$route.params,
									vendor_id: this.vendorId
								},
								query: {
									...this.$route.query,
								},
							},
							true
						)
					}
				})
				.finally(this.appEventBus.emit(this.appEvents.UPDATE_LOADING_STATUS, false))
		}
	}
}
</script>
